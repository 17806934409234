import React, { useState, useEffect, memo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { clearDivWrapper, DisplayFromFHIR } from './ResourceFunctions';
import { DataEntry, CodeEntry } from './DataEntryFormFunctions';
import { ButtonWithConfirmModal } from './ConfirmModal';
import { handleAdaptChange } from './AdaptItemDataEntryFunctions';
import { emptyTextNoData } from './CodeSystemLookup';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
  console.log("setResourceState from NarrativeEntry handleChange")
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}

const EditTextFunction = ({ functionid, name, placeholder, optionsList, value, setState }) => {
  if (functionid === "Dropdown") {
    return <Dropdown name={name} placeholder={placeholder}
      search={true}
      closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
      style={{ minWidth: "50%", width: "100%" }}
      options={optionsList.map((code, codeIndex) => { return { "key": codeIndex, "value": code, "text": code } })}
      value={value}
      onChange={(e, data) => {
        setState({ "divText": data.value });
      }}
    />
  }
}

const getStartingNarrativeFromStartingValue = (startingValue) => {
  let startingNarrative = JSON.parse(JSON.stringify(emptyTextNoData));
  if (startingValue) {
    if (startingValue.extension) { startingNarrative.extension = startingValue.extension; }
    if (startingValue.status) { startingNarrative.status = startingValue.status; }
    if (startingValue.div) { startingNarrative.div = startingValue.div; }
  }
  let startingDivTextValue = clearDivWrapper(startingNarrative.div);
  let startingDivValue = { "divText": startingDivTextValue };
  return [startingNarrative, startingDivValue];
};

const NarrativeEntry = memo(({ elementName, fieldLabel, startingValue = {}, setResourceState, startCollapsed,
  editTextStatus, editTextDiv, emptyTextDivValue, editTextFunction, generateNarrativeFromSingleEntryFunction,
  generateNarrativeFromEntryArrayFunction,
  generateSummaryFunction, entryFoi, entryFoiList, resourceDictionary, globalContext,
  path, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  const [revisedValueState, setRevisedValueState] = useState({ "revisedValue": null, "rationale": "" });
  const [startingValueState, setStartingValueState] = useState(null);

  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [editTextFunctionState, setEditTextFunctionState] = useState(null);

  const [narrativeState, setNarrativeState] = useState(null);
  const [narrativeDivState, setNarrativeDivState] = useState(null);
  const [startingNarrativeDivState, setStartingNarrativeDivState] = useState(null);
  const [startingNarrativeState, setStartingNarrativeState] = useState(null);
  const [narrativeIsGeneratedState, setNarrativeIsGeneratedState] = useState();

  useEffect(() => {
    let [startingNarrative, startingDivValue] = getStartingNarrativeFromStartingValue(startingValue);
    setStartingValueState(JSON.parse(JSON.stringify(startingValue)));
    setNarrativeState(JSON.parse(JSON.stringify(startingNarrative)));
    setStartingNarrativeState(JSON.parse(JSON.stringify(startingValue)));
    setNarrativeDivState(startingDivValue);
    setStartingNarrativeDivState(startingDivValue);
    setNarrativeIsGeneratedState(startingNarrative.status === "generated");
    setEditTextFunctionState(startingDivValue);
  }, [])

  const checkIfStatusAdditional = () => {
    return narrativeState.status === "additional";
  };

  useEffect(() => {
    if (adaptationReportState?.adaptOn) {
      if (revisedValueState.revisedValue !== null && adaptationReportState.adaptationDictionary &&
        JSON.stringify(revisedValueState.revisedValue) !== JSON.stringify(adaptationReportState.adaptationDictionary[path]?.initialValue) &&
        !(JSON.stringify(startingValue) === JSON.stringify(emptyTextNoData) && JSON.stringify(revisedValueState.revisedValue) === JSON.stringify(emptyTextNoData))) {
        let revisedValue = JSON.parse(JSON.stringify(revisedValueState.revisedValue));
        handleAdaptChange(adaptationReportState.adaptationDictionary, path,
          revisedValue,
          setAdaptationReportState, setChangeAvailableToSaveState, elementName,
          setResourceState, JSON.parse(JSON.stringify(revisedValueState.rationale || null)));
        setStartingValueState(revisedValue);
      }
    }
  }, [revisedValueState]);

  useEffect((() => {
    if (narrativeState && JSON.stringify(startingNarrativeState) !== JSON.stringify(narrativeState)) {
      let newNarrative = {};
      if (narrativeState.extension) { newNarrative.extension = narrativeState.extension; }
      if (narrativeState.status) { newNarrative.status = narrativeState.status; }
      if (narrativeState.div) { newNarrative.div = narrativeState.div; }
      if (Object.keys(newNarrative).length === 0 ||
        (newNarrative.status === "empty" && !newNarrative.div && !newNarrative.extension)) {
        newNarrative = null;
      }
      if (JSON.stringify(newNarrative) !== JSON.stringify(startingValue)) {
        handleChange(elementName, newNarrative, setResourceState);
        if (narrativeState && newNarrative && adaptationReportState?.adaptOn) {
          let newValue = JSON.parse(JSON.stringify(narrativeState));
          setRevisedValueState(prevState => { return { ...prevState, "revisedValue": newValue } });
        }
      }
    }
  }), [narrativeState]);

  useEffect((() => {
    if (narrativeDivState && startingNarrativeDivState.divText !== narrativeDivState.divText) {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' &&
        narrativeDivState.divText !== '[No data.]' &&
        narrativeDivState.divText !== '<p>[No data]</p>' &&
        narrativeDivState.divText !== '<p>[No data.]</p>' &&
        narrativeDivState.divText !== emptyTextDivValue &&
        narrativeDivState.divText !== '<p><br></p>' &&
        narrativeDivState.divText !== '<p><br/></p>' && narrativeDivState.divText !== '<p></p>') {
        if (narrativeIsGeneratedState) {
          setNarrativeState(prevState => { return { ...prevState, "status": "generated", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
          setNarrativeIsGeneratedState(false);
        } else {
          setNarrativeState(prevState => { return { ...prevState, "status": "additional", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
        }
      } else if (narrativeDivState.divText) {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      } else {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>' } });
      }
    }
  }), [narrativeDivState]);

  useEffect((() => {
    if (editTextFunction && editTextFunctionState.divText !== narrativeDivState.divText) {
      setNarrativeDivState({ "divText": editTextFunctionState.divText });
    }
  }), [editTextFunctionState]);

  const runFunctionForGenerateSummaryFunction = async () => {
    if (entryFoi) {
      let narrativeDivValue = clearDivWrapper(await generateSummaryFunction(entryFoi, resourceDictionary, globalContext));
      setNarrativeIsGeneratedState(true);
      setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
      setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
    }
  };
  const runFunctionForGenerateNarrativeFromSingleEntryFunction = () => {
    if (entryFoi) {
      let narrativeSummary = generateNarrativeFromSingleEntryFunction(resourceDictionary[entryFoi]);
      let divTextValue = clearDivWrapper(narrativeSummary.div);
      if (narrativeSummary.status === "generated") {
        setNarrativeIsGeneratedState(true);
      }
      setNarrativeDivState({ "divText": divTextValue, "generated": true });
      setNarrativeState(prevState => {
        return {
          ...prevState, "status": narrativeSummary.status,
          "div": narrativeSummary.div
        };
      });
    }
  };
  const runFunctionForGenerateNarrativeFromEntryArrayFunction = async () => {
    if (entryFoiList) {
      let divValue = await generateNarrativeFromEntryArrayFunction(entryFoiList, resourceDictionary, globalContext);
      let narrativeSummary = { "status": "generated", "div": divValue };
      let divTextValue = clearDivWrapper(divValue);
      if (divTextValue === "[No data.]" || divTextValue === "[No data]" || divTextValue === "<p>[No data.]</p>" || divTextValue === "<p>[No data]</p>" || divTextValue === "") {
        narrativeSummary.status = "empty";
      }
      if (narrativeSummary.status === "generated") {
        setNarrativeIsGeneratedState(true);
      }
      setNarrativeDivState({ "divText": divTextValue, "generated": true });
      setNarrativeState(prevState => {
        return {
          ...prevState, "status": narrativeSummary.status,
          "div": narrativeSummary.div
        };
      });
    }
  }

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR xhtml={startingNarrativeState?.div} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (editTextStatus) {
      return <div>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeEntry elementName='status' fieldLabel='Status' togglable={true}
            allowedValues={['generated', 'extensions', 'additional', 'empty']}
            startingValue={narrativeState?.status} setResourceState={setNarrativeState} />
          {editTextDiv ?
            <>{editTextFunction ?
              <EditTextFunction functionid={editTextFunction.functionid} name={editTextFunction.name}
                placeholder={editTextFunction.placeholder} optionsList={editTextFunction.optionsList}
                value={editTextFunction.value} setState={setEditTextFunctionState} />
              :
              <DataEntry datatype="xhtml" elementName='divText' fieldLabel='Narrative Summary'
                startingValue={narrativeDivState?.divText} setResourceState={setNarrativeDivState} />
            }</>
            :
            <DisplayFromFHIR xhtml={narrativeDivState?.divText} />}
        </div>
      </div>
    } else {
      return <div>
        {adaptationReportState?.adaptationDictionary?.[path]?.itemChanged && <>
          <p>Explain the rationale for the change to the {fieldLabel || elementName} value. (Optional)</p>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='string' elementName='rationale' fieldLabel='Rationale for change'
              startingValue={revisedValueState.rationale} setResourceState={setRevisedValueState} />
          </div>
          <br />
        </>}
        {generateSummaryFunction && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={runFunctionForGenerateSummaryFunction}
          disabled={!entryFoi} />}
        {generateNarrativeFromSingleEntryFunction && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={runFunctionForGenerateNarrativeFromSingleEntryFunction}
          disabled={!entryFoi || !resourceDictionary || !resourceDictionary[entryFoi]}
        />}
        {generateNarrativeFromEntryArrayFunction && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={async () => { await runFunctionForGenerateNarrativeFromEntryArrayFunction() }}
          disabled={!entryFoiList || !resourceDictionary}
        />}
        {narrativeState?.status &&
          <p style={{ marginBottom: "0px" }}>
            <b>Natural language summary: </b>
            (status: {narrativeState.status === "additional" ? "manually edited" : narrativeState.status})
          </p>
        }
        <div style={{ marginLeft: "24px" }}>
          {editTextDiv ?
            <>{editTextFunction ?
              <EditTextFunction functionid={editTextFunction.functionid} name={editTextFunction.name}
                placeholder={editTextFunction.placeholder} optionsList={editTextFunction.optionsList}
                value={editTextFunction.value} setState={setEditTextFunctionState} />
              :
              <DataEntry datatype="xhtml" elementName='divText' fieldLabel={fieldLabel}
                startingValue={narrativeDivState?.divText} setResourceState={setNarrativeDivState} />
            }</>
            :
            <DisplayFromFHIR xhtml={narrativeDivState?.divText} />}
        </div>
      </div>
    }
  }
});

export { NarrativeEntry, getStartingNarrativeFromStartingValue };