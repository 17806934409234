import { Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { retrieveIterativeElement, editIterativeState, getCodeDisplayOrText, jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const certaintyRowProcess = (certaintyRows, certainty, level, parent, indexTrail) => {
  let certaintyRow = {
    "parent": parent, "indexTrail": indexTrail, "level": level, "typeCodes": "",
    "rating": "", "rater": [], "description": "", "notes": []
  };
  certaintyRow["typeCodes"] = getCodeDisplayOrText(certainty.type);
  certaintyRow["rating"] = getCodeDisplayOrText(certainty.rating);
  certaintyRow["description"] = certainty.description;
  if (certainty.rater) {
    if (Array.isArray(certainty.rater)) {
      certaintyRow["rater"] = certainty.rater;
    } else if (typeof certainty.rater === 'string' && certainty.rater !== "") {
      certaintyRow["rater"] = [certainty.rater];
    }
  }
  if (certainty.note) {
    if (Array.isArray(certainty.note)) {
      certaintyRow["notes"] = certainty.note;
    } else if (typeof certainty.note === 'object' && certainty.note !== null && certainty.note.text) {
      certaintyRow["notes"] = [certainty.note];
    }
  }
  certaintyRows.push(certaintyRow);
  if (certainty.subcomponent) {
    for (let subcomponentIndex in certainty.subcomponent) {
      let subcomponent = certainty.subcomponent[subcomponentIndex];
      certaintyRows = certaintyRowProcess(certaintyRows, subcomponent, level + 1, certaintyRow["typeCodes"], [...indexTrail, subcomponentIndex]);
    }
  }
  return certaintyRows;
}

const CertaintyRows = ({ certaintyEntries, level, editMode, state, stateSetter }) => {
  let certaintyRows = [];
  for (let certaintyIndex in certaintyEntries) {
    let certainty = certaintyEntries[certaintyIndex];
    certaintyRows = certaintyRowProcess(certaintyRows, certainty, level, "", [certaintyIndex]);
  }
  return certaintyRows.map((row, rowIndex) => {
    let typePaddingLeft = (24 * row.level).toString() + "px";
    //.toString(16)
    let rowShadeNumber = 255;
    rowShadeNumber -= (12 * row.level);
    if (rowShadeNumber < 155) {
      rowShadeNumber = 155;
    }
    rowShadeNumber = (rowShadeNumber).toString(16); //Convert it to hexadecimal
    let rowShade = "#" + rowShadeNumber + rowShadeNumber + rowShadeNumber;

    let description;
    let rater;
    let notes = row.notes;
    if (state) {
      //description = state.description;
      description = retrieveIterativeElement(state, "subcomponent", "description", row.indexTrail);
      rater = retrieveIterativeElement(state, "subcomponent", "rater", row.indexTrail);
    } else {
      description = row.description;
      rater = row.rater;
    }

    return <Table.Row key={rowIndex} style={{ backgroundColor: rowShade }}>
      <Table.Cell style={{ verticalAlign: "top" }}>
        {editMode ?
          <span style={{ paddingLeft: typePaddingLeft, display: "flex" }}>
            {row.parent && <>Subcomponent of {row.parent}:<br /></>}
            {row.typeCodes}
          </span>
          :
          <span style={{ paddingLeft: typePaddingLeft, display: "flex" }}>{row.typeCodes}</span>
        }
      </Table.Cell>
      <Table.Cell style={{ verticalAlign: "top" }}>{row.rating}</Table.Cell>
      <Table.Cell style={{ verticalAlign: "top" }}>
        {editMode ?
          <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={description || ''} onChange={(e) => { editIterativeState(state, stateSetter, e.target.value, "subcomponent", "description", row.indexTrail) }} />
          :
          <>{description}</>
        }
      </Table.Cell>
      <Table.Cell style={{ verticalAlign: "top" }}>{notes.map((note, noteIndex) => {

        let noteValue;
        /*
        if (state) {
          noteValue = retrieveIterativeElement(state, "subcomponent", "note", row.indexTrail, noteIndex, "text");
        } else {
          noteValue = note.text;
        }
        */
        noteValue = note.text;
        return <p key={noteIndex}>
          {noteValue && <>{noteValue}</>}
        </p>
      })}</Table.Cell>
      <Table.Cell style={{ verticalAlign: "top" }}>
        {editMode ?
          <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Rater'} size="small" variant='outlined' value={rater || ''} onChange={(e) => { editIterativeState(state, stateSetter, e.target.value, "subcomponent", "rater", row.indexTrail) }} />
          :
          <>{Array.isArray(rater) ? rater.join(", ") : rater}</>
        }
      </Table.Cell>
    </Table.Row>
  })


}

const evidenceTextViewChangesToJson = (fhirJson, evidenceState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.meta = evidenceState.meta;
  fhirJson.language = evidenceState.language;
  fhirJson.text = evidenceState.text;
  fhirJson.contained = evidenceState.contained || "DELETEME";
  fhirJson.extension = evidenceState.extension || "DELETEME";
  fhirJson.modifierExtension = evidenceState.modifierExtension || "DELETEME";
  fhirJson.url = evidenceState.url || "DELETEME";
  fhirJson.identifier = evidenceState.identifier || "DELETEME";
  fhirJson.version = evidenceState.version || "DELETEME";
  fhirJson.versionAlgorithmString = evidenceState.versionAlgorithmString || "DELETEME";
  fhirJson.versionAlgorithmCoding = evidenceState.versionAlgorithmCoding || "DELETEME";
  fhirJson.name = evidenceState.name || "DELETEME";
  fhirJson.title = evidenceState.title || "DELETEME";
  fhirJson.status = evidenceState.status || "unknown";
  fhirJson.experimental = evidenceState.experimental || "DELETEME";
  fhirJson.date = evidenceState.date || "DELETEME";
  fhirJson.publisher = evidenceState.publisher || "DELETEME";
  fhirJson.contact = evidenceState.contact || "DELETEME";
  fhirJson.description = evidenceState.description || "DELETEME";
  fhirJson.useContext = evidenceState.useContext || "DELETEME";
  fhirJson.purpose = evidenceState.purpose || "DELETEME";
  fhirJson.usage = evidenceState.usage || "DELETEME";
  fhirJson.copyright = evidenceState.copyright || "DELETEME";
  fhirJson.copyrightLabel = evidenceState.copyrightLabel || "DELETEME";
  fhirJson.approvalDate = evidenceState.approvalDate || "DELETEME";
  fhirJson.lastReviewDate = evidenceState.lastReviewDate || "DELETEME";
  fhirJson.effectivePeriod = evidenceState.effectivePeriod || "DELETEME";
  fhirJson.topic = evidenceState.topic || "DELETEME";
  fhirJson.author = evidenceState.author || "DELETEME";
  fhirJson.editor = evidenceState.editor || "DELETEME";
  fhirJson.reviewer = evidenceState.reviewer || "DELETEME"
  fhirJson.endorser = evidenceState.endorser || "DELETEME";
  fhirJson.recorder = evidenceState.recorder || "DELETEME";
  fhirJson.relatedArtifact = evidenceState.relatedArtifact || "DELETEME";
  fhirJson.assertion = evidenceState.assertion || "DELETEME";
  fhirJson.note = evidenceState.note || "DELETEME";
  fhirJson.variableDefinition = evidenceState.variableDefinition || "DELETEME";
  fhirJson.synthesisType = evidenceState.synthesisType || "DELETEME";
  if (fhirJson.studyType) {
    if (!fhirJson.studyDesign) {
      if (Array.isArray(fhirJson.studyType)) {
        fhirJson.studyDesign = fhirJson.studyType;
      } else {
        fhirJson.studyDesign = [fhirJson.studyType];
      }
    }
    delete fhirJson.studyType;
  }
  fhirJson.studyDesign = evidenceState.studyDesign || "DELETEME";
  fhirJson.statistic = evidenceState.statistic || "DELETEME";
  fhirJson.certainty = evidenceState.certainty || "DELETEME";

  deleteEmptyElementsInObjectRecursive(fhirJson);
  let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  fhirJson = fhirJsonWithCiteAs;
  return fhirJson;
}

const createNewClassificationJson = (evidenceState) => {
  let contentArray = evidenceState.newClassifications;
  if (!contentArray) {
    return "";
  }
  let classifiedResourceType = "Evidence";
  let classifiedFoi = evidenceState.id;
  let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
  let classifedTitle = evidenceState.title || evidenceState.name || "[Untitled.]";
  let classificationJson = {
    "resourceType": "ArtifactAssessment",
    "artifactReference": {
      "reference": classifiedReference,
      "type": classifiedResourceType,
      "display": classifedTitle
    },
    "content": contentArray,
    "meta": {
      "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
    }
  };
  return classificationJson;
}

const builderUpdateEvidenceJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let evidenceState = formInputsStateRef.current.evidenceState;

  let fhirJson = jsonErrorCheck2("Evidence", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  if (fhirEntryState.activeIndex === 0) {
    fhirJson = evidenceTextViewChangesToJson(fhirJson, evidenceState);
    fhirJson = jsonErrorCheck2("Evidence", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  let newClassificationJson = createNewClassificationJson(evidenceState);

  return [fhirJson, newClassificationJson];
}

export { CertaintyRows, builderUpdateEvidenceJson };