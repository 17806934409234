import React, { useState, useEffect } from 'react';
import { getStringFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import SEVCO, { studyDesignCodeableConceptSet } from './SEVCO';

const focusReferenceResourceTypes = ['Medication', 'MedicinalProductDefinition', 'SubstanceDefinition', 'EvidenceVariable'];
const researchStudyDotPhaseValueSet = [
  { system: "http://hl7.org/fhir/research-study-phase", code: "n-a", display: "N/A" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "early-phase-1", display: "Early Phase 1" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "phase-1", display: "Phase 1" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "phase-1-phase-2", display: "Phase 1/Phase 2" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "phase-2", display: "Phase 2" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "phase-2-phase-3", display: "Phase 2/Phase 3" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "phase-3", display: "Phase 3" },
  { system: "http://hl7.org/fhir/research-study-phase", code: "phase-4", display: "Phase 4" }
];
const researchStudyDotPrimaryPurposeTypeValueSet = [
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "treatment", display: "Treatment" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "prevention", display: "Prevention" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "diagnostic", display: "Diagnostic" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "supportive-care", display: "Supportive Care" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "screening", display: "Screening" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "health-services-research", display: "Health Services Research" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "basic-science", display: "Basic Science" },
  { system: "http://hl7.org/fhir/research-study-prim-purp-type", code: "device-feasibility", display: "Device Feasibility" }
];
const researchStudyDotWhyStoppedValueSet = [
  { system: "http://hl7.org/fhir/research-study-reason-stopped", code: "accrual-goal-met", display: "Accrual Goal Met" },
  { system: "http://hl7.org/fhir/research-study-reason-stopped", code: "closed-due-to-toxicity", display: "Closed due to toxicity" },
  { system: "http://hl7.org/fhir/research-study-reason-stopped", code: "closed-due-to-lack-of-study-progress", display: "Closed due to lack of study progress" },
  { system: "http://hl7.org/fhir/research-study-reason-stopped", code: "temporarily-closed-per-study-design", display: "Temporarily closed per study design" }
];
const resultReferenceResourceTypes = ['Citation', 'Composition', 'EvidenceReport', 'DiagnosticReport'];
const siteReferenceResourceTypes = ['Location', 'ResearchStudy', 'Organization'];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.label?.length > 0) {
      innerDiv += resource.label.map((item) => {
        let type = getStringFromFHIR.CodeableConcept(item.type) || "Label";
        let value = item.value || "";
        return "<p><b>" + type + ": </b>" + value + "</p><br/>"
      }).join("");
    }
    if (resource.identifier?.length > 0) {
      innerDiv += resource.identifier.map((item) => {
        return "<p><b>Identifier: </b>" + getStringFromFHIR.Identifier(item) + "</p><br/>"
      }).join("");
    }
    if (resource.version) {
      innerDiv += "<p><b>Version: </b>" + resource.version + "</p><br/>";
    }
    if (resource.partOf?.length > 0) {
      innerDiv += resource.partOf.map((item) => {
        return "<p><b>Part of: </b>" + getStringFromFHIR.Reference(item) + "</p><br/>"
      }).join("");
    }
    if (resource.period) {
      innerDiv += "<p><b>Study start and end: </b>" + getStringFromFHIR.Period(resource.period) + "</p><br/>";
    }
    if (resource.progressStatus?.length > 0) {
      innerDiv += resource.progressStatus.map((item) => {
        let state = getStringFromFHIR.CodeableConcept(item.state) || "";
        let actual = "";
        if (item.actual === true) {
          actual = "Actual";
        }
        if (item.actual === false) {
          actual = "Anticipated";
        }
        let period = getStringFromFHIR.Period(item.period) || "";
        return "<p><b>Progress Status: </b>" + state + "(" + period + " " + actual + ")</p><br/>"
      }).join("");
    }
    if (resource.whyStopped) {
      innerDiv += "<p><b>Why stopped: </b>" + getStringFromFHIR.CodeableConcept(resource.whyStopped) + "</p><br/>";
    }
    if (resource.descriptionSummary) {
      innerDiv += "<p><b>Brief Description: </b>" + resource.descriptionSummary + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.primaryPurposeType) {
      innerDiv += "<p><b>Purpose Type: </b>" + getStringFromFHIR.CodeableConcept(resource.primaryPurposeType) + "</p><br/>";
    }
    if (resource.focus?.concept) {
      innerDiv += "<p><b>Focus: </b>" + getStringFromFHIR.CodeableConcept(resource.focus.concept) + "</p><br/>";
    }
    if (resource.focus?.reference) {
      innerDiv += "<p><b>Focus: </b>" + getStringFromFHIR.Reference(resource.focus.reference) + "</p><br/>";
    }
    if (resource.condition?.length > 0) {
      innerDiv += resource.condition.map((item) => {
        return "<p><b>Condition: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.keyword?.length > 0) {
      innerDiv += resource.keyword.map((item) => {
        return "<p><b>Keyword: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.classifier?.length > 0) {
      innerDiv += resource.classifier.map((item) => {
        return "<p><b>Classifier: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.region?.length > 0) {
      innerDiv += resource.region.map((item) => {
        return "<p><b>Region: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.phase) {
      innerDiv += "<p><b>Phase: </b>" + getStringFromFHIR.CodeableConcept(resource.phase) + "</p><br/>";
    }
    if (resource.studyDesign?.length > 0) {
      innerDiv += resource.studyDesign.map((item) => {
        return "<p><b>Study Design: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.extension?.length > 0) {
      let extensions = resource.extension || [];
      if (extensions.length > 0) {
        for (const extension of extensions) {
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-search-strategy") {
            let searchStrategy = getStringFromFHIR.Reference(extension.valueReference);
            innerDiv += "<p><b>Search Strategy:</b> " + searchStrategy + "</p><br/>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-systematic-review-eligibility-criteria") {
            let reviewEligCrit = getStringFromFHIR.Reference(extension.valueReference);
            innerDiv += "<p><b>Systematic Review Eligibility Criteria:</b> " + reviewEligCrit + "</p><br/>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sponsor-confidentiality-statement") {
            let confidentialityStatement = extension.valueString;
            innerDiv += "<p><b>Sponsor Confidentiality Statement:</b> " + confidentialityStatement + "</p><br/>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sae-reporting-method") {
            let saeReportingMethod = extension.valueString;
            innerDiv += "<p><b>Serious Adverse Event Reporting Method:</b> " + saeReportingMethod + "</p><br/>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-study-amendment") {
            innerDiv += "<h4>Study Amendment</h4>"
            for (const ext of extension.extension) {
              if (ext.url === "details" && ext.valueString) {
                innerDiv += "<p><b>Details:</b> " + ext.valueString + "</p><br/>";
              } else if (ext.url === "scope" && ext.valueString) {
                innerDiv += "<p><b>Scope:</b> " + ext.valueString + "</p><br/>";
              } else if (ext.url === "description" && ext.valueString) {
                innerDiv += "<p><b>Description:</b> " + ext.valueString + "</p><br/>";
              } else if (ext.url === "rationale" && ext.valueString) {
                innerDiv += "<p><b>Rationale:</b> " + ext.valueString + "</p><br/>";
              } else if (ext.url === "substantialImpactSafety" && ext.valueBoolean === true) {
                innerDiv += "<p><b>Substantial Impact on Safety:</b> YES</p><br/>";
              } else if (ext.url === "substantialImpactSafety" && ext.valueBoolean === false) {
                innerDiv += "<p><b>Substantial Impact on Safety:</b> No</p><br/>";
              } else if (ext.url === "substantialImpactSafetyComment" && ext.valueString) {
                innerDiv += "<p><b>Substantial Impact on Safety Comment:</b> " + ext.valueString + "</p><br/>";
              } else if (ext.url === "substantialImpactReliability" && ext.valueBoolean === true) {
                innerDiv += "<p><b>Substantial Impact on Reliability:</b> YES</p><br/>";
              } else if (ext.url === "substantialImpactsubstantialImpactReliabilitySafety" && ext.valueBoolean === false) {
                innerDiv += "<p><b>Substantial Impact on Reliability:</b> No</p><br/>";
              } else if (ext.url === "substantialImpactReliabilityComment" && ext.valueString) {
                innerDiv += "<p><b>Substantial Impact on Reliability Comment:</b> " + ext.valueString + "</p><br/>";
              } else if (ext.url === "primaryReason" && ext.valueCodeableConcept) {
                innerDiv += "<p><b>Primary Reason:</b> " + getStringFromFHIR.CodeableConcept(ext.valueCodeableConcept) + "</p><br/>";
              }
            }
          }
        }
      }
    }
    if (resource.protocol?.length > 0) {
      innerDiv += resource.protocol.map((item) => {
        return "<p><b>Protocol: </b>" + getStringFromFHIR.Reference(item) + "</p><br/>"
      }).join("");
    }
    if (resource.relatedArtifact?.length > 0) {
      innerDiv += resource.relatedArtifact.map((item) => {
        let type = item.type || "";
        let display = item.display || "";
        let citation = item.citation || "";
        let resource = item.resource || "";
        let resourceReference = getStringFromFHIR.Reference(item.resourceReference) || "";
        return "<p><b>Related Item (" + type + "): </b>" + display + " " + citation + " " + resource + " " + resourceReference + "</p><br/>"
      }).join("");
    }
    if (resource.note?.length > 0) {
      innerDiv += resource.note.map((note) => {
        return "<p><b>Note: </b>" + note.text + "</p><br/>"
      }).join("");
    }
    if (resource.associatedParty?.length > 0) {
      innerDiv += resource.associatedParty.map((item) => {
        let name = item.name || "";
        let role = getStringFromFHIR.CodeableConcept(item.role) || "";
        let party = getStringFromFHIR.Reference(item.party) || "";
        return "<p><b>Associated Party: </b>" + name + " (" + role + ") " + party + "</p><br/>"
      }).join("");
    }
    if (resource.site?.length > 0) {
      innerDiv += resource.site.map((item) => {
        return "<p><b>Site: </b>" + getStringFromFHIR.Reference(item) + "</p><br/>"
      }).join("");
    }
    if (resource.recruitment?.targetNumber) {
      innerDiv += "<p><b>Target number of participants: </b>" + resource.recruitment.targetNumber + "</p><br/>";
    }
    if (resource.recruitment?.actualNumber) {
      innerDiv += "<p><b>Actual number of participants: </b>" + resource.recruitment.actualNumber + "</p><br/>";
    }
    if (resource.recruitment?.eligibility) {
      innerDiv += "<p><b>Eligibility Criteria: </b>" + getStringFromFHIR.Reference(resource.recruitment.eligibility) + "</p><br/>";
    }
    if (resource.recruitment?.actualGroup) {
      innerDiv += "<p><b>Enrollment Group: </b>" + getStringFromFHIR.Reference(resource.recruitment.actualGroup) + "</p><br/>";
    }
    if (resource.comparisonGroup?.length > 0) {
      innerDiv += resource.comparisonGroup.map((item, itemIndex) => {
        let targetNumber = "";
        if (item.targetNumber || item.targetNumber === 0) {
          targetNumber = "<p><b>Target number of participants: </b>" + item.targetNumber + "</p><br/>";
        }
        let actualNumber = "";
        if (item.actualNumber || item.actualNumber === 0) {
          actualNumber = "<p><b>Actual number of participants: </b>" + item.actualNumber + "</p><br/>";
        }
        let eligibility = "";
        if (item.eligibility) {
          eligibility = "<p><b>Eligibility Criteria: </b>" + getStringFromFHIR.Reference(item.eligibility) + "</p><br/>";
        }
        let observedGroup = "";
        if (item.observedGroup) {
          observedGroup = "<p><b>Observed Group: </b>" + getStringFromFHIR.Reference(item.observedGroup) + "</p><br/>";
        }
        return "<p><b>Comparison Group" + (itemIndex + 1).toString() + ": </b></p>" +
          targetNumber + actualNumber + eligibility + observedGroup + "<br/>"
      }).join("");
    }
    if (resource.objective?.length > 0) {
      innerDiv += resource.objective.map((item, itemIndex) => {
        let name = item.name || "";
        let type = getStringFromFHIR.CodeableConcept(item.type) || "";
        let description = item.description || "";
        let objectiveDisplay = "<p><b>Objective" + (itemIndex + 1).toString() + ": </b>" + name + " (" + type + ") " + description + "</p><br/>";
        if (item.outcomeMeasure) {
          objectiveDisplay += item.outcomeMeasure.map((om, omIndex) => {
            let omName = om.name || "";
            let omType = getStringFromFHIR.CodeableConcept(om.type) || "";
            let omDescription = om.description || "";
            let omEndpoint = getStringFromFHIR.Reference(om.endpoint) || "";
            return "<p><b>Outcome Measure" + (omIndex + 1).toString() + ": </b>" + omName + " (" + omType + ") " + omDescription + " " + omEndpoint + "</p><br/>";
          }).join("");
        }
        return objectiveDisplay
      }).join("");
    }
    if (resource.outcomeMeasure?.length > 0) {
      innerDiv += resource.outcomeMeasure.map((item) => {
        let name = item.name || "";
        let type = "";
        if (item.type?.length) {
          type = getStringFromFHIR.CodeableConcept(item.type[0]) || "";
        }
        let description = item.description || "";
        let reference = getStringFromFHIR.Reference(item.reference) || "";
        return "<p><b>Objective: </b>" + name + " (" + type + ") " + description + " " + reference + "</p><br/>"
      }).join("");
    }
    if (resource.extension?.length > 0) {
      let extensions = resource.extension || [];
      if (extensions.length > 0) {
        for (const extension of extensions) {
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-identified") {
            innerDiv += "<p><b>Number of studies identified:</b> " + extension.valueUnsignedInt + "</p><br/>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-included") {
            innerDiv += "<p><b>Number of studies included:</b> " + extension.valueUnsignedInt + "</p><br/>";
          }
          if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-result-as-composition") {
            let resultAsComposition = getStringFromFHIR.Reference(extension.valueReference);
            innerDiv += "<p><b>Result:</b> " + resultAsComposition + "</p><br/>";
          }
        }
      }
    }
    if (resource.result?.length > 0) {
      innerDiv += resource.result.map((item) => {
        return "<p><b>Result: </b>" + getStringFromFHIR.Reference(item) + "</p><br/>"
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const ResearchStudyBuilder = ({ resourceState, setResourceState }) => {

  let systematicReviewProfile;
  let m11ResearchStudyProfile;
  if (resourceState.meta?.profile) {
    for (const profile of resourceState.meta.profile) {
      if (profile === "http://hl7.org/fhir/uv/ebm/StructureDefinition/systematic-review") {
        systematicReviewProfile = true;
      }
      if (profile === "http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-research-study") {
        m11ResearchStudyProfile = true;
      }
    }
  }
  let startingExtension = resourceState.extension || [];
  let startingExtensionStateValue = {
    "otherExtensions": [], "searchStrategy": [],
    "includedStudy": [], "excludedStudy": [], "resultAsComposition": []
  }
  if (Array.isArray(startingExtension) && startingExtension.length > 0) {
    for (const extension of startingExtension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-search-strategy':
          startingExtensionStateValue.searchStrategy.push(extension.valueReference);
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-systematic-review-eligibility-criteria':
          startingExtensionStateValue.systematicReviewEligibilityCriteria = extension.valueReference;
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-identified':
          startingExtensionStateValue.numberOfStudiesIdentified = extension.valueUnsignedInt;
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-included':
          startingExtensionStateValue.numberOfStudiesIncluded = extension.valueUnsignedInt;
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-included-study':
          startingExtensionStateValue.includedStudy.push(extension.valueReference);
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-excluded-study':
          startingExtensionStateValue.excludedStudy.push(extension.valueReference);
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-result-as-composition':
          startingExtensionStateValue.resultAsComposition.push(extension.valueReference);
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sponsor-confidentiality-statement':
          startingExtensionStateValue.confidentialityStatement = extension.valueString;
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sae-reporting-method':
          startingExtensionStateValue.saeReportingMethod = extension.valueString;
          break;
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-study-amendment':
          startingExtensionStateValue.studyAmendment = extension;
          break;
        default:
          startingExtensionStateValue.otherExtensions.push(extension)
      }
    }
  }

  const [extensionState, setExtensionState] = useState(startingExtensionStateValue);

  useEffect(() => {
    let newExtension = [];
    if (extensionState.otherExtensions) {
      newExtension = JSON.parse(JSON.stringify(extensionState.otherExtensions));
    }
    if (extensionState.searchStrategy && extensionState.searchStrategy.length > 0) {
      for (const instance of extensionState.searchStrategy) {
        newExtension.push({
          "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-search-strategy",
          "valueReference": instance
        });
      }
    }
    if (extensionState.systematicReviewEligibilityCriteria) {
      newExtension.push({
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-systematic-review-eligibility-criteria",
        "valueReference": extensionState.systematicReviewEligibilityCriteria
      });
    }
    if (!isNaN(extensionState.numberOfStudiesIdentified)) {
      newExtension.push({
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-identified",
        "valueUnsignedInt": extensionState.numberOfStudiesIdentified
      });
    }
    if (!isNaN(extensionState.numberOfStudiesIncluded) || extensionState.numberOfStudiesIncluded === "") {
      newExtension.push({
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-included",
        "valueUnsignedInt": extensionState.numberOfStudiesIncluded
      });
    }
    if (extensionState.includedStudy && extensionState.includedStudy.length > 0) {
      for (const instance of extensionState.includedStudy) {
        newExtension.push({
          "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-included-study",
          "valueReference": instance
        });
      }
    }
    if (extensionState.excludedStudy && extensionState.excludedStudy.length > 0) {
      for (const instance of extensionState.excludedStudy) {
        newExtension.push({
          "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-excluded-study",
          "valueReference": instance
        });
      }
    }
    if (extensionState.resultAsComposition && extensionState.resultAsComposition.length > 0) {
      for (const instance of extensionState.resultAsComposition) {
        newExtension.push({
          "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-result-as-composition",
          "valueReference": instance
        });
      }
    }
    if (extensionState.confidentialityStatement || extensionState.confidentialityStatement === "") {
      newExtension.push({
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sponsor-confidentiality-statement",
        "valueString": extensionState.confidentialityStatement
      });
    }
    if (extensionState.saeReportingMethod || extensionState.saeReportingMethod === "") {
      newExtension.push({
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sae-reporting-method",
        "valueString": extensionState.saeReportingMethod
      });
    }
    if (extensionState.studyAmendment && Object.keys(extensionState.studyAmendment).length > 0) {
      newExtension.push(extensionState.studyAmendment);
    }
    setResourceState(prevState => { return { ...prevState, "extension": newExtension } });
  }, [extensionState])

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="identification">Identification</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='ResearchStudyLabel' elementName='label' fieldLabel='Label'
          startCollapsed
          startingValue={resourceState.label} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Identifier' elementName='identifier' fieldLabel='Identifier'
          startCollapsed
          startingValue={resourceState.identifier} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='version' fieldLabel='Version'
          startingValue={resourceState.version} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Reference' elementName='partOf' fieldLabel='Part of (ResearchStudy)'
          referencedResourceTypes={['ResearchStudy']} enableCreation={true} startCollapsed
          startingValue={resourceState.partOf || null} setResourceState={setResourceState} />
      </div>
      <h3 id="status">Status</h3>
      <div style={{ marginLeft: "24px" }}>
        {m11ResearchStudyProfile && <DataEntry datatype='string'
          elementName='confidentialityStatement'
          fieldLabel='Seponsor Confidentiality Statement'
          startingValue={extensionState.confidentialityStatement}
          setResourceState={setExtensionState} />}
        <DataEntry datatype='Period' elementName='period' fieldLabel='Study start and end'
          startCollapsed
          startingValue={resourceState.period} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='ResearchStudyProgressStatus'
          elementName='progressStatus' fieldLabel='Progress Status' startCollapsed
          startingValue={resourceState.progressStatus} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='whyStopped' fieldLabel='Why stopped'
          valueSet={researchStudyDotWhyStoppedValueSet} startCollapsed
          startingValue={resourceState.whyStopped} setResourceState={setResourceState} />
      </div>
      <h3 id="subject">Subject</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='descriptionSummary' fieldLabel='Brief Description'
          startCollapsed
          startingValue={resourceState.descriptionSummary} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Full Description'
          startCollapsed
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='primaryPurposeType' fieldLabel='Purpose Type'
          valueSet={researchStudyDotPrimaryPurposeTypeValueSet} startCollapsed
          startingValue={resourceState.primaryPurposeType} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableReference'
          elementName='focus' fieldLabel='Focus' startCollapsed
          referencedResourceTypes={focusReferenceResourceTypes}
          startingValue={resourceState.focus} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept'
          elementName='condition' fieldLabel='Condition' startCollapsed
          startingValue={resourceState.condition} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept'
          elementName='keyword' fieldLabel='Keyword' startCollapsed
          startingValue={resourceState.keyword} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept'
          elementName='classifier' fieldLabel='Classifier' startCollapsed
          startingValue={resourceState.classifier} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept'
          elementName='region' fieldLabel='Region' startCollapsed
          startingValue={resourceState.region} setResourceState={setResourceState} />
      </div>
      <h3 id="protocol">Protocol</h3>
      <div style={{ marginLeft: "24px" }}>
        {m11ResearchStudyProfile && <DataEntry datatype='StudyAmendment'
          elementName='studyAmendment'
          fieldLabel='Study Amendment'
          startingValue={extensionState.studyAmendment} startCollapsed
          setResourceState={setExtensionState} />}
        {m11ResearchStudyProfile && <DataEntry datatype='string'
          elementName='saeReportingMethod'
          fieldLabel='Serious Adverse Event Reporting Method'
          startingValue={extensionState.saeReportingMethod}
          setResourceState={setExtensionState} />}
        {systematicReviewProfile && <>
          <DataEntry datatype='Reference'
            elementName='systematicReviewEligibilityCriteria'
            fieldLabel='Systematic Review Eligibility Criteria'
            enableCreation startCollapsed
            referencedResourceTypes={['Group']} setProfile="SystematicReviewEligibilityCriteria"
            startingValue={extensionState.systematicReviewEligibilityCriteria}
            setResourceState={setExtensionState} />
          <DataEntry asArray={true} datatype='Reference'
            elementName='searchStrategy'
            fieldLabel='Search Strategy' enableCreation startCollapsed
            referencedResourceTypes={['Group']} setProfile="SearchStrategy"
            startingValue={extensionState.searchStrategy}
            setResourceState={setExtensionState} />
        </>}
        {!systematicReviewProfile && <DataEntry datatype='CodeableConcept' elementName='phase' fieldLabel='Phase'
          valueSet={researchStudyDotPhaseValueSet} startCollapsed
          startingValue={resourceState.phase} setResourceState={setResourceState} />}
        <DataEntry asArray={true} datatype='CodeableConcept'
          elementName='studyDesign' fieldLabel='Study Design'
          valueSet={SEVCO.studyDesign} startCollapsed codeableConceptLevelValueSet={studyDesignCodeableConceptSet}
          startingValue={resourceState.studyDesign} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Reference'
          elementName='protocol' fieldLabel='Protocol' startCollapsed
          referencedResourceTypes={['PlanDefinition']}
          startingValue={resourceState.protocol} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='RelatedArtifact' startCollapsed
          elementName='relatedArtifact' fieldLabel='Related Item' allowedTypeValues="FHIR"
          startingValue={resourceState.relatedArtifact} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Annotation'
          elementName='note' fieldLabel='Note' startCollapsed
          startingValue={resourceState.note} setResourceState={setResourceState} />
      </div>
      <h3 id="organizations">Organizations</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='ResearchStudyAssociatedParty'
          elementName='associatedParty' fieldLabel='Associated Party' startCollapsed
          startingValue={resourceState.associatedParty} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Reference'
          elementName='site' fieldLabel='Site' enableCreation={true} startCollapsed
          referencedResourceTypes={siteReferenceResourceTypes}
          startingValue={resourceState.site} setResourceState={setResourceState} />
      </div>
      <h3 id="recruitment">Recruitment</h3>
      <div style={{ marginLeft: "24px" }}>
        {systematicReviewProfile ?
          <>This section is not relevant for a systematic review.</>
          :
          <DataEntry datatype='ResearchStudyRecruitment' elementName='recruitment' fieldLabel='Recruitment' startCollapsed
            startingValue={resourceState.recruitment} setResourceState={setResourceState} />
        }
      </div>
      <h3 id="variables">Variables</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='ResearchStudyComparisonGroup'
          elementName='comparisonGroup' fieldLabel='Comparison Group' startCollapsed
          startingValue={resourceState.comparisonGroup} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='ResearchStudyObjective'
          elementName='objective' fieldLabel='Objective' startCollapsed
          startingValue={resourceState.objective} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='ResearchStudyOutcomeMeasure'
          elementName='outcomeMeasure' fieldLabel='Outcome Measure (pre-2025 FHIR)' startCollapsed
          startingValue={resourceState.outcomeMeasure} setResourceState={setResourceState} />
      </div>
      <h3 id="results">Results</h3>
      <div style={{ marginLeft: "24px" }}>
        {systematicReviewProfile && <>
          <DataEntry datatype='unsignedInt'
            elementName='numberOfStudiesIdentified'
            fieldLabel='Number of studies identified'
            startingValue={extensionState.numberOfStudiesIdentified}
            setResourceState={setExtensionState} />
          <DataEntry datatype='unsignedInt'
            elementName='numberOfStudiesIncluded'
            fieldLabel='Number of studies included'
            startingValue={extensionState.numberOfStudiesIncluded}
            setResourceState={setExtensionState} />
          <DataEntry asArray={true} datatype='Reference'
            elementName='includedStudy'
            fieldLabel='Included study'
            referencedResourceTypes={['Citation', 'Composition', 'ResearchStudy']}
            enableCreation={true} startCollapsed
            startingValue={extensionState.includedStudy}
            setResourceState={setExtensionState} />
          <DataEntry asArray={true} datatype='Reference'
            elementName='excludedStudy'
            fieldLabel='Excluded study'
            referencedResourceTypes={['Citation', 'Composition', 'ResearchStudy']}
            enableCreation={true} startCollapsed
            startingValue={extensionState.excludedStudy}
            setResourceState={setExtensionState} />
          <DataEntry asArray={true} datatype='Reference'
            elementName='resultAsComposition'
            fieldLabel='Result (as Composition)' startCollapsed
            referencedResourceTypes={['Composition']} enableCreation={true}
            startingValue={extensionState.resultAsComposition}
            setResourceState={setExtensionState} />
        </>}
        <DataEntry asArray={true} datatype='Reference' elementName='result' fieldLabel='Result (as Reference)'
          startCollapsed
          referencedResourceTypes={resultReferenceResourceTypes} enableCreation={true}
          startingValue={resourceState.result} setResourceState={setResourceState} />
      </div>
    </div>
  </div>
}

export default ResearchStudyBuilder;
