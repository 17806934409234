const SEVCO = {
  studyDesign: [
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01001",
      "display": "interventional research"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01003",
      "display": "randomized assignment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01006",
      "display": "simple randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01007",
      "display": "stratified randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01008",
      "display": "block randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01009",
      "display": "adaptive randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01005",
      "display": "non-randomized assignment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01004",
      "display": "quasi-randomized assignment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01029",
      "display": "clinical trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01041",
      "display": "pragmatic clinical trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01038",
      "display": "expanded access study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01030",
      "display": "phase 1 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01031",
      "display": "exploratory investigational new drug study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01032",
      "display": "phase 1/phase 2 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01033",
      "display": "phase 2 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01034",
      "display": "phase 2/phase 3 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01035",
      "display": "phase 3 Trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01036",
      "display": "post-marketing study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01002",
      "display": "observational research"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01037",
      "display": "post-marketing surveillance study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01010",
      "display": "comparative study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01011",
      "display": "parallel cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01012",
      "display": "crossover cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01024",
      "display": "controlled crossover cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01025",
      "display": "single-arm crossover design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01013",
      "display": "case control design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01014",
      "display": "matching for comparison"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01020",
      "display": "family study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01021",
      "display": "twin study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01015",
      "display": "cluster as unit of allocation"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01023",
      "display": "non-comparative study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01016",
      "display": "uncontrolled cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01017",
      "display": "case report"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01022",
      "display": "population-based design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01044",
      "display": "ecological design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01027",
      "display": "cross sectional data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01028",
      "display": "longitudinal data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01018",
      "display": "time series design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01019",
      "display": "before and after comparison"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01045",
      "display": "primary data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01026",
      "display": "real world data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01039",
      "display": "real world data collection from healthcare records"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01050",
      "display": "real world data collection from personal health records"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01040",
      "display": "real world data collection from healthcare financing records"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01048",
      "display": "real world data collection from testing procedures"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01046",
      "display": "real world data collection from monitoring procedures"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01049",
      "display": "secondary data collection from prior research"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01042",
      "display": "secondary data collection from a registry"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01051",
      "display": "multisite data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01086",
      "display": "quantitative analysis"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01087",
      "display": "qualitative analysis"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01060",
      "display": "blinding of study participants"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01061",
      "display": "blinding of intervention providers"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01062",
      "display": "blinding of outcome assessors"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01063",
      "display": "blinding of data analysts"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01064",
      "display": "allocation concealment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01043",
      "display": "multicentric"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01052",
      "display": "includes patient-reported outcome"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01053",
      "display": "includes patient-centered outcome"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01054",
      "display": "includes disease-oriented outcome"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01085",
      "display": "includes process measure"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01089",
      "display": "study goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01096",
      "display": "evaluation goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01097",
      "display": "derivation goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01098",
      "display": "validation goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01088",
      "display": "comparison goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01091",
      "display": "comparative effectiveness goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01090",
      "display": "comparative efficacy goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01092",
      "display": "comparative safety goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01093",
      "display": "equivalence goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01094",
      "display": "non-inferiority goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01095",
      "display": "superiority goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01100",
      "display": "allocation ratio"
    }
  ],
  statisticType: [
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000668",
      "display": "absolute value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000047",
      "display": "count"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000669",
      "display": "sum"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000151",
      "display": "maximum observed value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000150",
      "display": "minimum observed value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000666",
      "display": "maximum possible value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000667",
      "display": "minimum possible value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000029",
      "display": "measure of central tendency"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000573",
      "display": "mean"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000664",
      "display": "mean of differences"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000658",
      "display": "mean time-to-event"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000396",
      "display": "geometric mean"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000574",
      "display": "median"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000659",
      "display": "median time-to-event"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000033",
      "display": "mode"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000397",
      "display": "harmonic mean"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000291",
      "display": "quantile"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000293",
      "display": "percentile"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000292",
      "display": "decile"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000152",
      "display": "quartile"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000167",
      "display": "first quartile"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000170",
      "display": "third quartile"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000613",
      "display": "difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000614",
      "display": "absolute difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000616",
      "display": "count difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000457",
      "display": "difference in means"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000617",
      "display": "difference in medians"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000424",
      "display": "risk difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000665",
      "display": "difference-in-differences"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000615",
      "display": "relative difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000615",
      "display": "relative mean difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000626",
      "display": "relative risk difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000100",
      "display": "standardized mean difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000618",
      "display": "Cohen’s d statistic"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000135",
      "display": "strictly standardized mean difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000319",
      "display": "Hedges’s g"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000320",
      "display": "Glass’s delta"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000634",
      "display": "reciprocal of difference"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000635",
      "display": "number needed to treat"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000637",
      "display": "number needed to screen to detect"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000636",
      "display": "number needed to screen to prevent"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000638",
      "display": "number needed to harm"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000184",
      "display": "ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000639",
      "display": "percentage"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000705",
      "display": "measurement accuracy"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000607",
      "display": "proportion"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000413",
      "display": "incidence"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000412",
      "display": "prevalence"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000233",
      "display": "sensitivity"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000134",
      "display": "specificity"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000416",
      "display": "positive predictive value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000619",
      "display": "negative predictive value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000621",
      "display": "diagnostic yield"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000620",
      "display": "risk"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000627",
      "display": "odds"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000645",
      "display": "rate"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000670",
      "display": "incidence rate"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000671",
      "display": "hazard rate"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000672",
      "display": "event rate"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000673",
      "display": "event frequency rate"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000674",
      "display": "event frequency"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000675",
      "display": "density"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000676",
      "display": "number density"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000704",
      "display": "concentration"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000610",
      "display": "measure of association"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000622",
      "display": "ratio-based measure of association"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000677",
      "display": "hazard ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000680",
      "display": "incidence rate ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000681",
      "display": "standardized incidence ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000182",
      "display": "odds ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000678",
      "display": "prevalence ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000245",
      "display": "risk ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000411",
      "display": "likelihood ratio positive"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000410",
      "display": "likelihood ratio negative"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000415",
      "display": "diagnostic accuracy"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000679",
      "display": "diagnostic odds ratio"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000524",
      "display": "phi coefficient"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000623",
      "display": "measure of agreement"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000682",
      "display": "kappa"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000683",
      "display": "simple chance-corrected agreement coefficient"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000630",
      "display": "Cohen’s kappa"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000631",
      "display": "modified Cohen’s kappa for more than 2 raters"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000629",
      "display": "Scott’s pi"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000632",
      "display": "misclassification rate"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000628",
      "display": "F1-score"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000611",
      "display": "measure of correlation"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000301",
      "display": "covariance"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000280",
      "display": "Pearson correlation coefficient"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000201",
      "display": "Spearman rank-order correlation coefficient"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000240",
      "display": "Kendall correlation coefficient"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000612",
      "display": "Goodman and Kruskal’s gamma"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000565",
      "display": "regression coefficient"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000685",
      "display": "measure of calibration"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000686",
      "display": "mean calibration"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000688",
      "display": "calibration intercept"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000687",
      "display": "calibration slope"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000028",
      "display": "measure of dispersion"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000035",
      "display": "range"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:00000164",
      "display": "interquartile range"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000237",
      "display": "standard deviation"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000684",
      "display": "standard deviation for sample"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000113",
      "display": "variance"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000643",
      "display": "variance for sample"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000624",
      "display": "Gini index"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000562",
      "display": "standard error"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000037",
      "display": "standard error of the mean"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000647",
      "display": "standard error of the proportion"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000648",
      "display": "standard error of the difference between independent means"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000649",
      "display": "standard error of the difference between independent proportions"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000455",
      "display": "credible interval"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000196",
      "display": "confidence interval"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000418",
      "display": "measure of heterogeneity"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000419",
      "display": "Cochran’s Q"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000420",
      "display": "I-squared"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000421",
      "display": "tau squared"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000209",
      "display": "area under the curve"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000608",
      "display": "area under the ROC curve"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000689",
      "display": "partial area under the ROC curve"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000691",
      "display": "area under the precision-recall curve"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000690",
      "display": "area under the value-time curve"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000633",
      "display": "threshold"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000069",
      "display": "degrees of freedom"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000609",
      "display": "hypothesis testing measure"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000700",
      "display": "p-value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000661",
      "display": "p-value for one-sided test"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000662",
      "display": "p-value for two-sided test"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000030",
      "display": "chi-square statistic"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000081",
      "display": "chi-square statistic for independence"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000708",
      "display": "Cochran-Armitage chi-square statistic for trend"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000698",
      "display": "chi-square statistic for homogeneity"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000309",
      "display": "chi-square statistic for goodness of fit"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000376",
      "display": "z-statistic"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000176",
      "display": "t-statistic"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000266",
      "display": "Bayes factor"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000660",
      "display": "posterior predictive p-value"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000104",
      "display": "z-score"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000699",
      "display": "t-score"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000702",
      "display": "prior probability"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "STATO:0000703",
      "display": "posterior probability"
    }
  ]
}

const studyDesignCodeableConceptSet = SEVCO.studyDesign.map(coding => {
  return { "coding": [coding] };
});

const statisticTypeCodeableConceptSet = SEVCO.statisticType.map(coding => {
  return { "coding": [coding] };
});

const continuousCodes = ["STATO:0000047", "STATO:0000669", "STATO:0000573", "STATO:0000664", "STATO:0000658", "STATO:0000574",
  "STATO:0000659", "STATO:0000613", "STATO:0000614", "STATO:0000639", "STATO:0000607", "STATO:0000413", "STATO:0000412",
  "STATO:0000620", "STATO:0000627", "STATO:0000645", "STATO:0000670", "STATO:0000671", "STATO:0000672", "STATO:0000673",
  "STATO:0000674"
];
const dichotomousCodes = ["STATO:0000047", "STATO:0000669", "STATO:0000639", "STATO:0000607", "STATO:0000620"];
const categoricalCodes = ["STATO:0000047", "STATO:0000669", "STATO:0000639", "STATO:0000607"];
const comparativeCodes = ["STATO:0000669", "STATO:0000573", "STATO:0000664", "STATO:0000613", "STATO:0000614", "STATO:0000616",
  "STATO:0000457", "STATO:0000617", "STATO:0000424", "STATO:0000615", "STATO:0000615", "STATO:0000626", "STATO:0000100",
  "STATO:0000677", "STATO:0000680", "STATO:0000681", "STATO:0000182", "STATO:0000678", "STATO:0000245", "STATO:0000081",
  "STATO:0000708"
];
const attributeCodes = ["STATO:0000035", "STATO:00000164", "STATO:0000237", "STATO:0000684", "STATO:0000643", "STATO:0000562",
  "STATO:0000037", "STATO:0000647", "STATO:0000648", "STATO:0000649", "STATO:0000455", "STATO:0000196", "STATO:0000418",
  "STATO:0000419", "STATO:0000420", "STATO:0000421", "STATO:0000700", "STATO:0000661", "STATO:0000662"
];

const continuousStatisticsConceptSet = statisticTypeCodeableConceptSet.filter(concept => continuousCodes.includes(concept.coding[0].code));
const dichotomousStatisticsConceptSet = statisticTypeCodeableConceptSet.filter(concept => dichotomousCodes.includes(concept.coding[0].code));
const categoricalStatisticsConceptSet = statisticTypeCodeableConceptSet.filter(concept => categoricalCodes.includes(concept.coding[0].code));
const comparativeStatisticsConceptSet = statisticTypeCodeableConceptSet.filter(concept => comparativeCodes.includes(concept.coding[0].code));
const attributeStatisticsConceptSet = statisticTypeCodeableConceptSet.filter(concept => attributeCodes.includes(concept.coding[0].code));

export default SEVCO;
export { studyDesignCodeableConceptSet, statisticTypeCodeableConceptSet, continuousStatisticsConceptSet,
  dichotomousStatisticsConceptSet, categoricalStatisticsConceptSet, comparativeStatisticsConceptSet, attributeStatisticsConceptSet
 };
