import React, { useState, useEffect, useContext } from 'react';
import submitToFevirServer from './SubmitToFevirServer';
import { SimpleResourceFieldViewer, submitUpdatedFhirResource } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import { SimpleResourceEdit, CompositionResourceEdit } from './ResourceBuilder';
import ActivityDefinitionDisplay from './ActivityDefinitionDisplay';
import AdaptationDisplay from './AdaptationDisplay';
import AdaptationEdit from './AdaptationEdit';
import BundleDisplay from './BundleDisplay';
import ComparisonDisplay from './ComparisonDisplay';
import { ComparisonEdit } from './ComparisonEdit';
import ClassificationDisplay from './ClassificationDisplay';
import ClassificationEdit from './ClassificationEdit';
import RatingDisplay from './RatingDisplay';
import RatingEdit from './RatingEdit';
import CommentDisplay from './CommentDisplay';
import CommentEdit from './CommentEdit';
import CodeSystemDisplay from './CodeSystemDisplay';
import ComparativeEvidenceReportViewerTool from './ComparativeEvidenceReportViewingTool';
import ComparativeEvidenceSynthesisReportViewerTool from './ComparativeEvidenceSynthesisReportViewingTool';
import CompositionDisplay from './CompositionDisplay';
import CompositionTableReportViewerTool from './CompositionEvidenceTableViewingTool';
import OutcomeMeasureSynthesisReportViewerTool from './CompositionEvidenceSynthesisTableViewingTool';
import { EvidenceDisplay } from './EvidenceDisplay';
import EvidenceVariableDisplay from './EvidenceVariableDisplay';
import { EvidenceReportPackageViewerTool } from './EvidenceReportPackageViewingTool';
import GroupDisplay from './GroupDisplay';
import GuidelineViewerTool from './GuidelineViewingTool';
import LibraryDisplay from './LibraryDisplay';
import ListDisplay from './ListDisplay';
import M11ReportViewerTool from './M11ReportViewingTool';
import MeasureDisplay from './MeasureDisplay';
import PlanDefinitionDisplay from './PlanDefinitionDisplay';
import ProjectDisplay from './ProjectDisplay';
import ProjectEdit from './ProjectEdit';
import { RecommendationJustificationEdit } from './RecommendationJustificationEdit';
import RecommendationJustificationDisplay from './RecommendationJustificationDisplay';
import RecommendationViewerTool from './RecommendationViewingTool';
import ResearchStudyDisplay from './ResearchStudyDisplay';
import SoftwareScriptDisplay from './SoftwareScriptDisplay';
import SoftwareScriptEdit from './SoftwareScriptEdit';
import SummaryOfFindingsViewerTool from './SummaryOfFindingsViewingTool';
import ValueSetDisplay from './ValueSetDisplay';
import FevirContext from './FevirContext';

const ResourceBuilderViewer = ({ changeFormState, formInputsStateRef, fhirJson, fhirEntryState, setFhirEntryState,
  changeFhirEntryState, history, referencedResourceState, setReferencedResourceState,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState,
  setChangeAvailableToSaveState, update }) => {
  let editMode = fhirEntryState.editMode;
  const globalContext = useContext(FevirContext);

  const containedResourceDictionary = {};
  if (fhirJson?.contained) {
    for (const resource of fhirJson.contained) {
      let displayTitle = resource.title || resource.name;
      if (!displayTitle) {
        if (resource.practitioner?.display || resource.organization?.display) {
          displayTitle = resource.practitioner?.display + " at " + resource.organization?.display;
        }
        if (resource.site?.[0].reference.startsWith("#")) {
          let siteId = resource.site[0].reference.replace("#", "");
          displayTitle = containedResourceDictionary[siteId] || "REPLACE-" + siteId;
        }
      }
      if (Array.isArray(displayTitle)) {
        displayTitle = displayTitle[0]?.text || displayTitle.toString();
      }
      containedResourceDictionary[resource.id] = displayTitle;
    }
    if (fhirJson.resourceType === "ResearchStudy") {
      for (const resourceId in containedResourceDictionary) {
        if (containedResourceDictionary[resourceId].startsWith("REPLACE-")) {
          let siteId = containedResourceDictionary[resourceId].replace("REPLACE-", "");
          containedResourceDictionary[resourceId] = containedResourceDictionary[siteId];
        }
      }
    }
  }

  const [classificationsArrayState, setClassificationsArrayState] = useState([]);
  const [classificationsLoadedState, setClassificationsLoadedState] = useState(false);

  useEffect(() => {
    let resourceTypeName = fhirJson.resourceType || "Resource";
    let profileName;
    if (fhirJson.meta?.profile?.length) {
      if (fhirJson.meta.profile[0].includes('http://hl7.org/fhir/uv/ebm/StructureDefinition/')) {
        let forProfileName = fhirJson.meta.profile[0].replace('http://hl7.org/fhir/uv/ebm/StructureDefinition/', '');
        let profileNameSplit = forProfileName.split('-');
        profileName = profileNameSplit.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1) }).join('');
      } else if (fhirJson.meta.profile[0].includes('https://hl7.org/fhir/uv/ebm/StructureDefinition/')) {
        let forProfileName = fhirJson.meta.profile[0].replace('https://hl7.org/fhir/uv/ebm/StructureDefinition/', '');
        let profileNameSplit = forProfileName.split('-');
        profileName = profileNameSplit.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1) }).join('');
      } else if (fhirJson.meta.profile[0].includes('https://fevir.net/Profile/')) {
        profileName = fhirJson.meta.profile[0].replace('https://fevir.net/Profile/', '');
      }
    }
    let toolDisplayName = profileName || resourceTypeName;
    if (toolDisplayName === "Recommendation" || toolDisplayName === "Guideline" ||
      toolDisplayName === "SummaryOfFindings" || toolDisplayName === "ParticipantFlowReport" ||
      toolDisplayName === "ComparativeEvidenceReport" || toolDisplayName === "BaselineMeasureReport" ||
      toolDisplayName === "ComparativeEvidenceSynthesisReport" ||
      toolDisplayName === "ParticipantFlowReport" || toolDisplayName === "OutcomeMeasureReport" ||
      toolDisplayName === "EvidenceReportPackage" || toolDisplayName === "SystematicReview") {
      toolDisplayName = "Computable Publishing®: " + toolDisplayName;
      if (fhirEntryState.editMode) {
        toolDisplayName = toolDisplayName + " Authoring Tool";
      } else {
        toolDisplayName = toolDisplayName + " Viewing Tool";
      }
    } else {
      toolDisplayName = "FEvIR®: " + toolDisplayName;
      if (fhirEntryState.editMode) {
        toolDisplayName = toolDisplayName + " Builder";
      } else {
        toolDisplayName = toolDisplayName + " Viewer";
      }
    }
    changeFormState(toolDisplayName, "pageTitle");
  }, [fhirEntryState.editMode]);

  //warning about race condition with async at top of useEffect but this is the pattern that works

  const loadResourceClassifiers = async () => {
    let classificationsArray;
    let response = { "success": false };
    try {
      response = await getResourceClassifiers(fhirJson.id);
      classificationsArray = response.classificationsArray;
      setClassificationsArrayState(classificationsArray);
      setClassificationsLoadedState(true);
    } catch {
      setClassificationsArrayState(null);
      console.log('Some error has occurred in getResourceClassifiers');
    }
  };

  useEffect(() => {
    loadResourceClassifiers();
  }, [fhirJson.id]);

  const getResourceClassifiers = async (targetFoi) => {
    let returningdata = { "success": false, "classificationsArray": [] }
    const body = {
      'functionid': "getresourceclassifiers",
      'targetfoi': parseInt(targetFoi),
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    if (!response || !response.success) {
      returningdata = { "success": false, "classificationsArray": null }
      //globalContext.openAlert({ "header": "", "content": "Problem getting the resource Classifications." });

      return returningdata;
    }
    /*
    if (Array.isArray(response.resourceClassifiers)) {
      let resourceClassifiers = response.resourceClassifiers;
      let classificationIndex = {};
      let classificationsArray = [];
      if (resourceClassifiers.length > 0) {
        for (const classifier of resourceClassifiers) {
          let typeJson = null;
          if ((classifier.typecode || classifier.typedisplay) && classifier.typetext) {
            typeJson = { "coding": [{ "code": classifier.typecode || "", "display": classifier.typedisplay || "" }], "text": classifier.typetext };
          } else if (classifier.typecode || classifier.typedisplay) {
            typeJson = { "coding": [{ "code": classifier.typecode || "", "display": classifier.typedisplay || "" }] };
          } else if (classifier.typetext) {
            typeJson = { "text": classifier.typetext }
          } else {
            typeJson = { "text": "[Untyped]" };
          }
          let typeString = JSON.stringify(typeJson);
          let classifierJson = null;
          if ((classifier.classifiercode || classifier.classifierdisplay) && classifier.classifiertext) {
            classifierJson = { "coding": [{ "code": classifier.classifiercode || "", "display": classifier.classifierdisplay || "" }], "text": classifier.classifiertext };
          } else if (classifier.classifiercode || classifier.classifierdisplay) {
            classifierJson = { "coding": [{ "code": classifier.classifiercode || "", "display": classifier.classifierdisplay || "" }] };
          } else if (classifier.classifiertext) {
            classifierJson = { "text": classifier.classifiertext }
          }
          let authorDisplay;
          if (classifier.authordisplay) {
            authorDisplay = classifier.authordisplay;
          }
          let freetoshare = classifier.freetoshare ?? true;
          if (classificationIndex[typeString]) {
            classificationIndex[typeString].classifier.push(classifierJson);
          } else {
            classificationIndex[typeString] = {
              "informationType": "classifier",
              "type": typeJson,
              "classifier": [classifierJson],
              "author": { "display": authorDisplay },
              "freeToShare": freetoshare
            };
            if (!authorDisplay) {
              delete classificationIndex[typeString].author;
            }
          }
        }
        for (const typeIndex in classificationIndex) {
          classificationsArray.push(classificationIndex[typeIndex]);
        }
      }
      returningdata = { "success": true, "classificationsArray": classificationsArray }
    }
    */
    //TODO -- Does this next part get used?  Is there any response.resourceClassifiers?
    if (typeof response.resourceClassifiers === "object" && !Array.isArray(response.resourceClassifiers)) {
      let resourceClassifiers = response.resourceClassifiers;
      let classificationIndex = {};
      let classificationsArray = [];
      for (const type in resourceClassifiers) {
        classificationIndex[type] = {
          "informationType": "classifier",
          "type": type,
          "classifier": [],
          "author": { "display": "" },
          "freeToShare": true
        };
        for (const classifier of resourceClassifiers[type]) {
          if (classifier.classifiervalue && classifier.freeToShare !== false) {
            classificationIndex[type].classifier.push(classifier.classifiervalue);
            if (classifier.authordisplay) {
              classificationIndex[type].author.display = classifier.authordisplay;
            }
          }
        }
        if (!classificationIndex[type].author.display) {
          delete classificationIndex[type].author;
        }
        classificationsArray.push(classificationIndex[type]);
      }
      returningdata = { "success": true, "classificationsArray": classificationsArray }
    }
    return returningdata;
  }

  if (editMode) {
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id)

    if (fhirJson.resourceType === "ActivityDefinition" || fhirJson.resourceType === "Bundle" ||
      fhirJson.resourceType === "CodeSystem" || fhirJson.resourceType === "Evidence" ||
      fhirJson.resourceType === "EvidenceVariable" || fhirJson.resourceType === "Group" ||
      fhirJson.resourceType === "Library" || fhirJson.resourceType === "List" ||
      fhirJson.resourceType === "Measure" || fhirJson.resourceType === "PlanDefinition" ||
      fhirJson.resourceType === "ResearchStudy" || fhirJson.resourceType === "ValueSet"
    ) {
      return <SimpleResourceEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson}
        resourceState={resourceState} setResourceState={setResourceState}
        sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
        globalContext={globalContext} />
    }
    if (fhirJson.resourceType === "ArtifactAssessment" && fhirJson.meta?.profile) {
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") {
        return <AdaptationEdit fhirJson={fhirJson} fhirEntryState={fhirEntryState}
          formInputsStateRef={formInputsStateRef} resourceId={fhirJson.id} setFhirEntryState={setFhirEntryState}
          submitUpdatedFhirResource={submitUpdatedFhirResource}
          changeFormState={changeFormState} classificationsLoadedState={classificationsLoadedState}
          classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") {
        return <ComparisonEdit fhirJson={fhirJson} fhirEntryState={fhirEntryState}
          formInputsStateRef={formInputsStateRef} resourceId={fhirJson.id} setFhirEntryState={setFhirEntryState}
          submitUpdatedFhirResource={submitUpdatedFhirResource}
          changeFormState={changeFormState} classificationsLoadedState={classificationsLoadedState}
          classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/classification" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/classification") {
        return <ClassificationEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary} citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comment" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comment") {
        return <CommentEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary} citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/rating" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/rating") {
        return <RatingEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary} citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
        fhirJson.meta.profile[0] === "https://fevir.net/Profile/RecommendationJustification") {
        return <RecommendationJustificationEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} />
      }
    }
    if (fhirJson.resourceType === "Composition") {
      let profile = "none";
      if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
        profile = "Recommendation";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
        profile = "Guideline";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
        profile = "M11Report";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
        profile = "EvidenceReportPackage";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
        profile = "SummaryOfFindings";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
        profile = "ComparativeEvidenceReport";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-synthesis-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-synthesis-report")) {
        profile = "ComparativeEvidenceSynthesisReport";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
        profile = "BaselineMeasureReport";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
        profile = "ParticipantFlowReport";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
        profile = "OutcomeMeasureReport";
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report")) {
        profile = "OutcomeMeasureSynthesisReport";
      }
      return <CompositionResourceEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson}
        resourceState={resourceState} setResourceState={setResourceState}
        sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
        globalContext={globalContext} profile={profile} update={update} previousVersionLoaded={fhirEntryState.previousVersionLoaded}
        fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
        history={history} changeFormState={changeFormState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    }
    if (fhirJson.resourceType === "Project") {
      return <ProjectEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson}
        fhirEntryState={fhirEntryState}
      />
    }
    if (fhirJson.resourceType === "SoftwareScript") {
      return <SoftwareScriptEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
  }

  if (fhirJson.resourceType === "ActivityDefinition") {
    return <ActivityDefinitionDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "ArtifactAssessment" && fhirJson.meta?.profile) {
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") {
      return <AdaptationDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") {
      return <ComparisonDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/classification" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/classification") {
      return <ClassificationDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comment" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comment") {
      return <CommentDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/rating" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/rating") {
      return <RatingDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
      fhirJson.meta.profile[0] === "https://fevir.net/Profile/RecommendationJustification") {
      return <RecommendationJustificationDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    }
  }
  if (fhirJson.resourceType === "Bundle") {
    return <BundleDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "CodeSystem") {
    return <CodeSystemDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Composition") {
    if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
      return <RecommendationViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
      return <GuidelineViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
      return <M11ReportViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
      return <EvidenceReportPackageViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
      return <ComparativeEvidenceReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-synthesis-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-synthesis-report")) {
      return <ComparativeEvidenceSynthesisReportViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
      return <CompositionTableReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} containedResourceDictionary={containedResourceDictionary}
        reportType={"Baseline Measure"} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
      return <CompositionTableReportViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} reportType={"Participant Flow"} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
      return <CompositionTableReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} containedResourceDictionary={containedResourceDictionary}
        reportType={"Outcome Measure"} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report")) {
      return <OutcomeMeasureSynthesisReportViewerTool fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
      return <SummaryOfFindingsViewerTool fhirEntryState={fhirEntryState} fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState}
        formInputsStateRef={formInputsStateRef} setFhirEntryState={setFhirEntryState} changeFormState={changeFormState}
        classificationsLoadedState={classificationsLoadedState} globalContext={globalContext} history={history} />
    } else {
      return <CompositionDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
  }
  if (fhirJson.resourceType === "Evidence") {
    return <EvidenceDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "EvidenceVariable") {
    return <EvidenceVariableDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Group") {
    return <GroupDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState}
      classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Library") {
    return <LibraryDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "List") {
    return <ListDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Measure") {
    return <MeasureDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "PlanDefinition") {
    return <PlanDefinitionDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Project") {
    return <ProjectDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
      formInputsStateRef={formInputsStateRef} fhirEntryState={fhirEntryState}
      referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
      history={history} changeFormState={changeFormState} setFhirEntryState={setFhirEntryState} />
  }
  if (fhirJson.resourceType === "ResearchStudy") {
    return <ResearchStudyDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "SoftwareScript") {
    return <SoftwareScriptDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "ValueSet") {
    return <ValueSetDisplay fhirJson={fhirJson} containedResourceDictionary={containedResourceDictionary}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }

  return <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
}

export default ResourceBuilderViewer;